import React from 'react';
import ReactDOM from 'react-dom/client';
import store from "../app/store";
import {Provider} from "react-redux";
import {useGetSiteSelectionDataQuery} from "../services/drystock";
import {ErrorPage} from "../common/ErrorPage";
import {TankDisplay} from "./TankDisplay";
import {Loader, PageLoader} from "../common/Loader";
import {SiteSelect, SiteSelectTank} from "../api/drystockAPI";

const SiteCard = ({site}: { site: SiteSelect }) => {
    let addressData = null;

    if (site.address) {
        addressData = <h6 className='card-subtitle mb-2 text-muted'>{site.address}</h6>;
    }

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h2 className="card-title">{site.siteName}</h2>
                {addressData}
                <div className="tank-basic mt-4 mb-4">
                    <div className='d-flex flex-wrap m-n3'>

                        {site.tanks.map((tank) =>
                            <TankBasic tank={tank} />
                        )}
                    </div>
                </div>
                <div className="d-flex align-items-end ">
                    <a className="btn btn-primary stretched-link" href={site.selectionLink}>Select</a>
                </div>
            </div>
        </div>
    );
}

const SiteSelection = () => {
    const {
        data,
        isError,
        isLoading,
    } = useGetSiteSelectionDataQuery();

    if (isLoading) {
        return <PageLoader show={true}/>
    }

    if (isError || !data) {
        return <ErrorPage show={true}/>
    }


    return <div>
        {data.map(datum => <SiteCard site={datum}/>)}
    </div>
}
const TankBasic = ({tank}: { tank: SiteSelectTank }) => {
    return (
        <div className=' m-3'>

            <div className="d-flex flex-row">
                <div className='align-self-center'>
                    <TankDisplay height={200} width={35} volume={tank.volume} capacity={tank.capacity} water={0}/>
                </div>
                <div className="ml-2 align-self-center flex-wrap" style={{width: '200px'}}>
                    <h5 style={{overflow: "hidden",whiteSpace:"nowrap", textOverflow:"ellipsis"}}>{tank.description}</h5>

                    <dl className='m-0'>
                        <dt>{tank.ullageText}</dt>
                        <dd>{tank.ullage}</dd>
                        <dt>{tank.capacityText}</dt>
                        <dd>{tank.capacity}</dd>
                        <dt>{tank.volumeText}</dt>
                        <dd>{tank.volume}</dd>
                    </dl>
                </div>
            </div>

        </div>
    )
}


export default () => {

    const container = document.getElementById('site-selection');

    if (container) {
        const root = ReactDOM.createRoot(container)

        // const siteId = container.getAttribute('data-site-id');
        root.render(
            <Provider store={store}>
                <SiteSelection/>
            </Provider>
        )
    }
}