// Need to use the React-specific entry point to import createApi
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {ItemOrder, PurchaseOrder} from "../orders/OrderForm";
import {Item, LocationData, SiteSelect} from "../api/drystockAPI";
import {addCsrf} from "./fetch-options";
import {Tank} from "../order-wetstock/OrderWetstock";

// Define a service using a base URL and expected endpoints
export const drystockApi = createApi({
    reducerPath: 'drystockApi',
    tagTypes: ['Item', 'Tank', 'Order', 'ItemLocationData', 'SiteSelectData'],
    baseQuery: fetchBaseQuery(addCsrf({
        baseUrl: '/'
    })),
    endpoints: (builder) => ({
        getOutstandingItemOrders: builder.query<ItemOrder[], { itemId: string }>({
            query: (arg) => `itemdrystock/outstanding-orders/${arg.itemId}`,
        }),

        getItems: builder.query<Item[], {
            overheads?: boolean,
            wetstock?: boolean,
            supplierId?: number,
            siteId: number,
            search?: string,
            outstandingOrders: boolean,
            expand?: string[],
            pageNumber?: number
        }>({
            query: (arg) => {

                const params = new URLSearchParams();

                params.set('outstandingOrders', arg.outstandingOrders ? '1' : '0');

                if (arg.expand) {
                    params.set('expand', arg.expand.join(','));
                }

                if (arg.pageNumber && arg.pageNumber > 0) {
                    params.set('page', arg.pageNumber.toString())
                }

                if (arg.search) {
                    params.set('q', arg.search);
                }

                if (arg.supplierId) {
                    params.set('supplierId', arg.supplierId.toString());
                }

                if (arg.overheads) {
                    params.set('overheads', arg.overheads ? '1' : '0');
                }

                if (arg.wetstock) {
                    params.set('wetstock', arg.wetstock ? '1' : '0');
                }

                return `itemdrystock/${arg.siteId}/search?${params.toString()}`;
            },

            serializeQueryArgs: ({endpointName, queryArgs}) => {
                let expandTags = '';
                if (queryArgs.expand) {
                    expandTags = queryArgs.expand.join(',')
                }

                return `${endpointName}-${queryArgs.siteId}-${queryArgs.search}-${expandTags}-${queryArgs.outstandingOrders ? 1 : 0}-${queryArgs.overheads ? 1 : 0}-${queryArgs.wetstock ? 1 : 0}`;
            },
            // Always merge incoming data to the cache entry
            merge: (currentCache, newItems, otherArgs) => {
                if (!otherArgs.arg.pageNumber || otherArgs.arg.pageNumber === 0) {
                    return newItems;
                }

                currentCache.push(...newItems)
            },
            // Refetch when the page arg changes
            forceRefetch({currentArg, previousArg}) {
                if (!currentArg || !previousArg) {
                    return true;
                }

                return currentArg.pageNumber !== previousArg.pageNumber;
            },
        }),

        getStockLocationData: builder.query<LocationData, { id: number }>({
            query: ({id}) => `/itemdrystock/location-pre-adjustment-data/${id}`,

            providesTags: (result, error, queryArg) => {
                if (result) {
                    let id = queryArg.id;
                    return [{type: 'ItemLocationData', id}];
                }
                return [];
            }
        }),

        getItem: builder.query<Item, { id: string }>({
            query: ({id}) => `itemdrystock/view/${id}`,

            providesTags: (result) => {
                if (result) {
                    const id = result.itemid;
                    return [{type: 'Item', id}];
                }
                return [];
            }
        }),
        updateItem: builder.mutation<Item, Partial<Item>>({

            query: (body) => {
                return {
                    url: `itemdrystock/ajax-update/${body.itemid}`,
                    method: 'POST',
                    body: body
                }
            },
            invalidatesTags: (result, error, arg) =>
                [{type: 'Item', id: arg.itemid}],
        }),
        getTanks: builder.query<Tank[], { siteId: number }>({
            query: ({siteId}) => `tank/${siteId}/index`,

            providesTags: (result) => {
                if (result) {
                    return [{type: 'Tank'}];
                }
                return [];
            }
        }),

        getSiteSelectionData: builder.query<SiteSelect[], void>({
            query: () => `rvmsite/site-selection-data`,

            providesTags: (result) => {
                // if (result) {
                //     return [{type: 'Tank'}];
                // }
                return [{'type': 'SiteSelectData'}];
            }
        }),

        postAdjustment: builder.mutation<void, { storageTankId: number, siteId: number }>({
            query: ({storageTankId, siteId}) => ({
                url: `tank/adjustment`,
                method: 'POST',
                body: {
                    storageTankId
                }
            }),
            invalidatesTags: (result, error, arg) =>
                [{type: 'Tank'}],
        }),

        postAdjustmentLocation: builder.mutation<void, {
            itemId: number,
            locationId: number,
            data: { quantity: number }
        }>({
            query: ({locationId, itemId, data}) => ({
                url: `/itemdrystock/make-location-pre-adjustment/?itemId=${itemId}&stockLocationId=${locationId}`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (result, error, arg) =>
                [{type: 'ItemLocationData', id: arg.itemId}],
        }),

        postFinaliseLocationStocktake: builder.mutation<void, {
            itemId: number,
        }>({
            query: ({ itemId}) => ({
                url: `/itemdrystock/finalise-location-adjustment/${itemId}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) =>
                [{type: 'ItemLocationData', id: arg.itemId}],
        }),

        postDip: builder.mutation<void, any>({
            query: (tankReading) => ({
                url: `tank/dip`,
                method: 'POST',
                body: tankReading,
            }),
            invalidatesTags: (result, error, arg) =>
                [{type: 'Tank'}],
        }),
        //  TODO drop data type
        postDrop: builder.mutation<void, { itemOrder: ItemOrder, dropData: any }>({
            query: (args) => ({
                url: `tank/drop/${args.itemOrder.itemorderid}`,
                method: 'POST',
                body: args.dropData
            }),

            invalidatesTags: (result, error, arg) =>
                [
                    {type: 'Tank'},
                    {type: 'Order', id: arg.itemOrder.supplierorderid},]
        }),


        postReceiveAll: builder.mutation<void, { supplierOrderId: number, dateTime: string }>({
            query: (args) => ({
                url: `supplierorder/supply-all`,
                method: 'POST',
                body: args
            }),

            invalidatesTags: (result, error, arg) =>
                [{type: 'Order', id: arg.supplierOrderId}]
        }),


        postNewDrop: builder.mutation<void, any>({
            query: (dropData) => ({
                url: 'tank/drop-new-order',
                method: 'POST',
                body: dropData
            }),

            invalidatesTags: (result, error, arg) =>
                [{type: 'Tank'}]
        }),

        getOrder: builder.query<PurchaseOrder, { id: number }>({
            query: ({id}) => `supplierorder/itemorders?id=${id}`,

            providesTags: (result) => {
                if (result) {

                    return [{type: 'Order', id: result.supplierorderid}];

                }
                return [];
            }
        }),


        updateOrder: builder.mutation<PurchaseOrder, Partial<PurchaseOrder>>({

            query: (body) => {
                return {
                    url: `supplierorder/update/${body.supplierorderid}`,
                    method: 'POST',
                    body: body
                }
            },
            invalidatesTags: (result, error, arg) =>
                [{type: 'Order', id: arg.supplierorderid}],
        }),


        receiveItemOrder: builder.mutation<ItemOrder, Partial<ItemOrder>>({
            query: (body) => ({
                url: `itemorder/receive`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: (result, error, arg) =>
                [{type: 'Order', id: arg.supplierorderid}],
        }),

        updateItemOrder: builder.mutation<ItemOrder, { id: number, data: Partial<ItemOrder> }>({
            query: (args) => ({
                url: `itemorder/update/${args.id}`,
                method: 'POST',
                body: args.data
            }),
            invalidatesTags: (result, error, arg) => (
                [{type: 'Order', id: arg.data.supplierorderid}]
            )
        }),

        deleteOrder: builder.mutation<{ redirect: string }, number>({
            query: (args) => ({
                url: `supplierorder/delete/${args}`,
                method: 'POST',
            }),
            // invalidatesTags: (result, error, arg) => (
            //     [{type: 'Order', id: arg}]
            // )
        }),

        deleteItemOrder: builder.mutation<void, ItemOrder>({
            query: (args) => ({
                url: `itemorder/delete/${args.itemorderid}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) => (
                [{type: 'Order', id: arg.supplierorderid}]
            )
        }),


        postItemOrder: builder.mutation<ItemOrder, Partial<ItemOrder>>({
            query: (body) => ({
                url: `itemorder/create`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: (result, error, arg) =>
                [{type: 'Order', id: arg.supplierorderid}],
        }),

        postPlaceOrder: builder.mutation<void, number>({
            query: (id) => ({
                url: `/supplierorder/place-order/${id}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) =>
                // TODO invalidate itemorders too?
                [{type: 'Order', id: arg}],
        }),

        postCreditOrder: builder.mutation<{ redirect: string }, number>({
            query: (id) => ({
                url: `/supplierorder/credit/${id}`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) =>
                // TODO invalidate itemorders too?
                [{type: 'Order', id: arg}],
        }),

        postReceiveOrder: builder.mutation<void, number>({
            query: (id) => ({
                url: `/supplierorder/receive/${id}`,
                method: 'POST',
            }),


            invalidatesTags: (result, error, arg) =>
                // TODO invalidate itemorders too?
                [{type: 'Order', id: arg}],
        }),
        postDuplicateOrder: builder.mutation<{ redirect: string }, number>({
            query: (id) => ({
                url: `/supplierorder/duplicate/${id}`,
                method: 'POST',
            }),


        }),
    }),


})

export const {
    usePostReceiveAllMutation,
    useUpdateOrderMutation,
    useGetOrderQuery,
    useReceiveItemOrderMutation,
    usePostItemOrderMutation,
    usePostPlaceOrderMutation,
    usePostCreditOrderMutation,
    usePostReceiveOrderMutation,
    usePostDuplicateOrderMutation,
    useUpdateItemOrderMutation,
    useDeleteItemOrderMutation,
    useDeleteOrderMutation,
    useGetOutstandingItemOrdersQuery,
    useGetStockLocationDataQuery,
    useGetItemsQuery,
    useGetItemQuery,
    useUpdateItemMutation,
    usePostDropMutation,
    useGetTanksQuery,
    useGetSiteSelectionDataQuery,
    usePostDipMutation,
    usePostAdjustmentMutation,
    usePostNewDropMutation,
    usePostAdjustmentLocationMutation,
    usePostFinaliseLocationStocktakeMutation
} = drystockApi