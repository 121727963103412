import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {WetstockFormNewOrder} from "./WetstockFormExistingOrder";
import {TankDipModal} from "./TankDipModal";
import {TankDisplay} from "./TankDisplay";
import {useGetTanksQuery, usePostAdjustmentMutation, usePostDipMutation} from "../services/drystock";
import {ErrorPage} from "../common/ErrorPage";
import {LoaderOverlay, PageLoader} from "../common/Loader";

interface WetstockModalProps {
    tank: Tank
}

export const TankDropAction = ({tank}: WetstockModalProps) => {
    const [showModal, setShowModal] = useState<number>();


    return (
        <React.Fragment>
            <button className="btn btn-primary m-1" onClick={() => setShowModal(
                Date.now())}>
                {tank.tankDropText}
            </button>
            <Modal
                show={showModal !== undefined}
                onHide={() => setShowModal(undefined)}>
                {/* @ts-ignore */}
                <Modal.Header closeButton>
                    <Modal.Title>{tank.fuelDropText}&nbsp;<small>{tank.description}</small></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <WetstockFormNewOrder
                        closeOnFinish
                        onCancel={() => setShowModal(undefined)}
                        suppliers={tank.dropdownSupplierOptions}
                        tankId={tank.storagetankid}
                        id="wetstock-form"
                    />

                </Modal.Body>
            </Modal>

        </React.Fragment>
    );
}

interface TankDipActionProps {
    tank: Tank;
}

const TankDipAction = ({tank}: TankDipActionProps) => {
    const [showModal, setShowModal] = useState<number>();

    const [postDip, postDipStatus] = usePostDipMutation();

    return (
        <React.Fragment>
            <button className="btn btn-primary m-1" onClick={() => setShowModal(Date.now())}>{tank.tankDipText}</button>
            <TankDipModal
                tankDipText={tank.tankDipText}
                fuelVolumeWithUnitText={tank.fuelVolumeWithUnitText}
                waterContentWithUnitText={tank.waterContentWithUnitText}
                submitting={(postDipStatus.status === 'pending')}
                tankName={tank.description}
                storageTankId={tank.storagetankid}
                show={!!(showModal && (!postDipStatus.fulfilledTimeStamp || showModal > postDipStatus.fulfilledTimeStamp))}
                onClose={() => setShowModal(undefined)}
                onSubmit={(tankReading: any) => {
                    postDip(tankReading);
                }}
            />
        </React.Fragment>
    );
}

interface FuelDrop {
    dateSupplied: string;
    volume: number;
    unitCost: number;
    supplierName: string;
}

interface TankReading {
    volume: number;
    watercontent: number;
    datetimeread: string;
    localDateTime: string;
    manualdip: boolean;
}

export interface Supplier {
    contactid: number;
    organisationname: string;
}

export interface Tank {
    fuelVolumeWithUnitText: string;
    waterContentWithUnitText: string;
    abbreviateddescription: string;
    canPostAdjustment: boolean;
    capacity: number;
    capacityText: string;
    description: string;
    dipsLink: string;
    dropdownSupplierOptions: Supplier[];
    dropsLink: string;
    fuelDropText: string;
    gradeName: string;
    latestDrop: FuelDrop;
    latestTankReading: TankReading;
    name: string;
    postAdjustmentText: string;
    //TODO load separately
    storagetankid: number
    tankDipText: string;
    tankDropDateText: string;
    tankDropText: string;
    tankReadingDateText: string;
    tanknumber: string | null
    ullageText: string;
    ullage: number;
    variance: number;
    varianceText: string;
    viewDipsText: string;
    viewDropsText: string
    volume: number
    volumeText: string
    waterContentText: string
}

interface TRProps {
    tankReading?: TankReading;
    variance?: number;
    varianceText: string;
    tankReadingDateText: string;
    waterContentText: string;
    volumeText: string;
}


// TODO make paginated loader
const TankReadingDisplay = ({
                                tankReading,
                                variance,
                                varianceText,
                                tankReadingDateText,
                                waterContentText,
                                volumeText
                            }: TRProps) => {
    if (!tankReading) {
        return <p>
            No tank readings
        </p>
    } else {

        let varianceDisplay = null;

        if (variance !== undefined) {
            varianceDisplay = <React.Fragment>
                <dt className="col-sm-6">{varianceText}</dt>
                <dd className="col-sm-6">{variance}</dd>

            </React.Fragment>;
        }
        return (

            <dl className="row ">

                <dt className="col-sm-6">{tankReadingDateText}</dt>
                <dd className="col-sm-6">

                    {tankReading.localDateTime}

                </dd>
                <dt className="col-sm-6">{volumeText}</dt>
                <dd className="col-sm-6">

                    {tankReading.volume}

                </dd>
                <dt className="col-sm-6">{waterContentText}</dt>
                <dd className="col-sm-6">

                    {tankReading.watercontent}

                </dd>

                {varianceDisplay}

            </dl>
        )
    }
};

interface FDProps {
    fuelDrop?: FuelDrop
    tankDropDateText: string,
    volumeText: string,

}

// TODO make paginated loader
const FuelDropDisplay = ({fuelDrop, tankDropDateText, volumeText}: FDProps) => {
    if (!fuelDrop) {
        return <p>
            No fuel drops
        </p>
    } else {
        return (

            <dl className="row d-flex flex-fill">

                <dt className="col-sm-6">{tankDropDateText}</dt>
                <dd className="col-sm-6">

                    {fuelDrop.dateSupplied}

                </dd>
                <dt className="col-sm-6">{volumeText}</dt>
                <dd className="col-sm-6">

                    {fuelDrop.volume}

                </dd>

            </dl>
        )
    }
};

export interface TankProps {
    siteId: number
    tank: Tank
    refreshing: boolean
}


interface OrderWetstockProps {
    siteId: number
}

export const OrderWetstock = ({siteId}: OrderWetstockProps) => {
    const {
        data,
        isSuccess,
        isError,
        error,
        isLoading,
        isFetching
    } = useGetTanksQuery({siteId});

    if (isLoading) {
        return <PageLoader show={true}/>
    }

    if (isError || !data) {
        return <ErrorPage show={true}/>
    }


    return (
        <div>
            {data.map((tank) => <Tank tank={tank} siteId={siteId} refreshing={isFetching}/>)}
        </div>
    )
}


export const Tank = ({tank, siteId, refreshing}: TankProps) => {


    const [postAdjustment, adjustmentStatus]
        = usePostAdjustmentMutation();

    let postAdjustmentButton = null, water = 0;

    if (tank.canPostAdjustment) {
        postAdjustmentButton = <button disabled={adjustmentStatus.isLoading} className="btn btn-secondary m-1"
                                       onClick={() => postAdjustment({
                                           siteId: siteId,
                                           storageTankId: tank.storagetankid
                                       })}>
            {tank.postAdjustmentText}
        </button>
    }

    return (
        <React.Fragment>
            <div className="card mb-3">
                <LoaderOverlay show={refreshing}/>

                <div className="card-body">
                    <h2 className="card-title">{tank.tanknumber} <small>{tank.description}</small> <small
                        className="text-muted">{tank.gradeName}</small>
                    </h2>
                    {/* TODO attribute not correct */}

                    <div className="d-flex flex-row flex-wrap bd-highlight">
                        <div className="p-2 align-content-center">
                            <TankDisplay
                                height={300}
                                width={125}
                                volume={tank.volume}
                                capacity={tank.capacity}
                                water={water}
                            />
                        </div>
                        <div className="d-flex flex-column">
                            <div className="p-2 ">
                                <dl className="row ">

                                    <dt className="col-sm-6">{tank.capacityText}</dt>
                                    <dd className="col-sm-6">{tank.capacity}</dd>

                                    <dt className="col-sm-6">{tank.volumeText}</dt>
                                    <dd className="col-sm-6">{tank.volume}</dd>

                                    <dt className="col-sm-6">{tank.ullageText}</dt>
                                    <dd className="col-sm-6">{tank.capacity - tank.volume}</dd>


                                </dl>
                            </div>
                            <div className="p-2 border-top d-flex flex-column">
                                <TankReadingDisplay
                                    varianceText={tank.varianceText}
                                    waterContentText={tank.waterContentText}
                                    tankReadingDateText={tank.tankReadingDateText}
                                    tankReading={tank.latestTankReading}
                                    variance={tank.variance}
                                    volumeText={tank.volumeText}
                                />

                                <div className="d-flex flex-wrap">
                                    {postAdjustmentButton}

                                    {tank.latestTankReading ?
                                        <a className="btn btn-primary m-1" href={tank.dipsLink}>
                                            {tank.viewDipsText}
                                        </a>
                                        : null}
                                    <TankDipAction tank={tank}/>
                                </div>

                            </div>
                            <div className="p-2 border-top d-flex flex-column">
                                <FuelDropDisplay fuelDrop={tank.latestDrop} volumeText={tank.volumeText}
                                                 tankDropDateText={tank.tankDropDateText}/>

                                <div className="d-flex flex-wrap">
                                    {
                                        tank.latestDrop ?
                                            <a className="btn btn-primary m-1"
                                               href={tank.dropsLink}>{tank.viewDropsText}</a>
                                            : null}
                                    <TankDropAction tank={tank}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
